<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('ball_game.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('ball_game.head_data') }}</v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col cols="12" xs="6" md="6" xl="6">
                <v-text-field v-model="formData.title" :label="$t('ball_game.detail_input1')" outlined dense
                  disabled></v-text-field>
              </v-col>

              <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                <v-text-field v-model="formData.round" :rules="formRule.round" :label="$t('ball_game.detail_input2')"
                  type="date" :min="minDate" outlined dense></v-text-field>
              </v-col>

              <v-row class="ma-0 d-flex align-baseline">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeStart" :rules="formRule.timeStart"
                    :label="$t('ball_game.detail_input7')" type="date" :min="minDate" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <date-picker-input ref="timeHourStart" :time="timeHourStart" @setValue="timeHourStart = $event"
                    :label="$t('ball_game.detail_input13')" :showTime="true" />
                </v-col>
              </v-row>

              <v-row class="ma-0 d-flex align-baseline">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeEnd" :rules="formRule.timeEnd"
                    :label="$t('ball_game.detail_input8')" type="date" :min="minDate" outlined
                    dense></v-text-field></v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <date-picker-input ref="timeHourEnd" :time="timeHourEnd" @setValue="timeHourEnd = $event"
                    :label="$t('ball_game.detail_input14')" :showTime="true" />
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeBetUse" :items="rewardTypes" item-text="name" item-value="value"
                    :label="$t('ball_game.detail_input9')" outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.betUse" :label="$t('ball_game.detail_input10')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>

              <v-col class="pa-0">
                <v-row class="ma-0">
                  <v-card-title class="pt-0 pb-2 px-3">รางวัล</v-card-title>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                    <v-select v-model="formData.typeReward" :items="rewardTypes"
                      :rules="[v => !!v || this.$t('ball_game.detail_input11')]" :label="$t('ball_game.detail_input11')"
                      outlined dense disabled></v-select>
                  </v-col>
                  <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                    <v-text-field v-model="formData.rewardAmount" :rules="formRule.rewardAmount"
                      :label="$t('ball_game.detail_input12')" type="number" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                    <v-text-field v-model="formData.multiplyTurnOver" :rules="formRule.multiplyAmount"
                      :label="$t('ball_game.reward_multiple')" type="number" outlined dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

          <!-- Reward type and amount Input -->
          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.head1') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.match[0].homeName" :rules="formRule.text"
                    :label="$t('ball_game.detail_input3')" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.match[0].awayName" :rules="formRule.text"
                    :label="$t('ball_game.detail_input4')" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.head2') }} </v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.match[1].homeName" :rules="formRule.text"
                    :label="$t('ball_game.detail_input3')" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.match[1].awayName" :rules="formRule.text"
                    :label="$t('ball_game.detail_input4')" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'predict-football' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('ball_game.create') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'

export default {
  components: {
    StaffLimitAccessPage,
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        round: [value => (value !== undefined && value !== null && value !== '') || this.$t('alert_list.list6')],
        timeStart: [value => (value !== undefined && value !== null && value !== '') || this.$t('alert_list.list7')],
        timeEnd: [value => (value !== undefined && value !== null && value !== '') || this.$t('alert_list.list8')],
        text: [value => (value !== undefined && value !== null && value !== '') || this.$t('alert_list.list9')],
        rewardAmount: [
          value => (value !== undefined && value !== null) || this.$t('alert_list.list10'),
          value => /^\d+$/.test(value) || this.$t('alert_list.list10_2'),
        ],
        multiplyAmount: [
          value => (value !== undefined && value !== null) || this.$t('alert_list.list10'),
          value => /^[0-9]+$/.test(value) || this.$t('alert_list.list10_2'),
        ],
      },
      title: this.$t('ball_game.head_list3'),
      formData: {
        title: '',
        round: '',
        timeStart: '',
        timeEnd: '',
        typeBetUse: 'coin',
        betUse: 1,
        typeReward: 'money',
        rewardAmount: null,
        multiplyTurnOver: 0,
        matchAmount: 2,
        match: [
          { homeName: '', awayName: '' },
          { homeName: '', awayName: '' },
        ],
      },
      timeHourStart: moment().format('HH:mm:ss'),
      timeHourEnd: moment().format('HH:mm:ss'),
      minDate: moment().format('YYYY-MM-DD'),
      rewardTypes: ['point', 'coin', 'money'],

      breadcrumbsItems: [
        {
          text: this.$t('ball_game.head'),
          disabled: false,
          href: '/predictLotto',
        },
        {
          text: this.$t('ball_game.head_list5'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.formData.title = this.title
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'ทายบอล',
              url: window.location.href,
              detail: 'สร้าง ทายบอล',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    linkTo(name) {
      const id = this.$route.params
      this.$router.push({ name: name, params: { id } })
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: `${this.$t('ball_game.confirm')}${this.formData.title}`,
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.addPredictFootballData()
            }
          })
      }
    },
    async addPredictFootballData() {
      this.formData.timeStart = `${this.formData.timeStart} ${this.timeHourStart}:00`
      this.formData.timeEnd = `${this.formData.timeEnd} ${this.timeHourEnd}:00`
      this.formData.multiplyTurnOver = parseInt(this.formData.multiplyTurnOver, 10)
      this.formData.rewardAmount = parseInt(this.formData.rewardAmount, 10)

      this.loading = true
      try {
        let res = await this.$store.dispatch('addFootball', this.formData)
        if (res.status === 'success') {
          this.$swal.fire(`${this.$t('bsll_game.create_complete1')} ${this.formData.title} ${this.$t('bsll_game.create_complete2')}`, '', 'success').then(action => {
            this.$router.push({ name: 'predict-football' })
          })
        } else if (res.code === 400) {
          this.$swal.fire(`${this.$t('bsll_game.create_fail1_1')} ${this.formData.title} ${this.$t('bsll_game.create_fail1_2')}`, '', 'error')
        } else {
          this.$swal.fire(this.$t('bsll_game.create_fail2'), '', 'error')
        }
        // this.$refs.form.reset()
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }

      this.loading = false
    },
  },
  watch: {
    'formData.round': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.title = this.title + newValue
      }
    },
  },
}
</script>
